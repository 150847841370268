import React, { FunctionComponent } from 'react'
import { Markup } from 'interweave'
import { Link } from 'react-router-dom'
import useTranslation from '../../hooks/useTranslation'
import Equipment from '../../entities/Equipment'
import InfoButton from '../info-button'
import InfoI from '../info-i'
import EraseIcon from '../../assets/media/icons/erase.svg'
import EditIcon from '../../assets/media/icons/edit.svg'
import style from './overviewEquipmentGroups.css'
import MediaImg from '../media-img'
import { Table, Td, Tr } from '../table'

export type Props = {
    equipments: Equipment[]
    url: string
    deselectEquipment: Function
    openInfoModal: Function
}

const OverviewEquipmentTable: FunctionComponent<Props> = (props) => {
    const {
        equipments,
        url,
        openInfoModal,
        deselectEquipment,
    } = props
    const { t } = useTranslation()

    if (!equipments) {
        return null
    }

    const uniqueEquipments = Array.from(new Set(equipments.map((e) => e.id)))
        .map((id) => equipments.find((e) => e.id === id))

    const rowElements = uniqueEquipments.map((equipment) => {
        if (!equipment) {
            return null
        }

        const {
            id,
            evaKey,
            name,
            media,
            price,
            content,
            selectable,
            isStandard,
            count,
            unitPrice,
            replacedBy,
            replacedByName,
        } = equipment

        const editUrl = `${url}?#${encodeURIComponent(id)}`

        const deselectButtonClickHandler = (): void => {
            deselectEquipment(id)
        }

        const openModal = (activeMedia: string | null = null): Function => () => openInfoModal({
            title: name,
            media,
            activeMedia,
            content,
        })

        const imgElement = media && <MediaImg alt={name} media={media} width={80} />
        const imgInfoButtonElement = imgElement !== null ? (
            <InfoButton openModal={openModal('image')} dataCy={`info-img-button-${id.replace(/\\/g, '.')}`}>
                {imgElement}
            </InfoButton>
        ) : null

        const hasInfo = media !== null && media.length > 0
        const infoButtonElement = hasInfo ? (
            <InfoButton openModal={openModal()} dataCy={`info-button-${id.replace(/\\/g, '.')}`}>
                <InfoI isForced={false} />
            </InfoButton>
        ) : null

        const deselectButtonElement = (selectable) ? (
            <button
                onClick={deselectButtonClickHandler}
                title={t('overviewSelection.deselectButtonLabel')}
                className={style.optionBtn}
                type="button"
                data-cy={`deselect-button-${id.replace(/\\/g, '.')}`}
            >
                <EraseIcon className={style.icon} />
            </button>
        ) : null

        const editButtonElement = (!isStandard) ? (
            <Link
                to={editUrl}
                title={t('overviewSelection.editButtonLabel')}
                className={style.optionBtn}
                data-cy={`edit-button-${id.replace(/\\/g, '.')}`}
            >
                <EditIcon className={style.icon} />
            </Link>
        ) : null

        const priceElement = isStandard
            ? t('standard-equipment-price')
            : <Markup content={price} />

        let countComponent = null

        if (count && count > 1) {
            const priceLabel = t('accessory.itemPrice')
            const amountLabel = t('accessory.quantity')

            countComponent = (
                <div className={style.quantityWrapper}>
                    <div className={style.itemPriceWrapper}>
                        {priceLabel}
                        <span className={style.quantityAmount}>
                            {unitPrice}
                        </span>
                    </div>
                    <div className={style.quantityAmount}>
                        {amountLabel}
                        <span className={style.quantityAmount}>
                            {count}
                        </span>
                    </div>
                </div>
            )
        }

        let replaceContext = undefined
        if (replacedBy) {
            const replacedByEquipmentName = replacedByName ? replacedByName : replacedBy
            replaceContext = (
                <div className={style.replacedBy}><strong>{t('summaryData.replacedBy')} </strong>
                    <Markup content={replacedByEquipmentName}/>
                </div>
            )
        }

        return (
            <Tr key={id}>
                <Td className={style.nameCol}>
                    <div className={style.nameColInner}>
                        <div className={style.nameContainer}>
                            <div>
                                <Markup tagName="span" content={name} />
                                <span className={style.evaKey}>{evaKey}</span>
                            </div>
                            {replaceContext}
                        </div>
                        <div>
                            {imgInfoButtonElement}
                        </div>
                    </div>
                </Td>
                <Td className={style.priceCol}>
                    {countComponent}
                    {priceElement}
                </Td>
                <Td className={style.optionCol}>
                    <ul className={style.optionsList}>
                        <li>{editButtonElement}</li>
                        <li>{infoButtonElement}</li>
                        <li>{deselectButtonElement}</li>
                    </ul>
                </Td>
            </Tr>
        )
    })

    return (
        <Table>
            <tbody>
                {rowElements}
            </tbody>
        </Table>
    )
}

export default OverviewEquipmentTable
