import React, { FunctionComponent, useState } from 'react'
import useTranslation from '../../hooks/useTranslation'
import LastConfigurationInfo from '../../entities/LastConfigurationInfo'
import useOnMount from '../../hooks/useOnMount'
import LoadingIndicatorWrapper from '../loading-indicator-wrapper'
import style from './lastConfigurations.css'
import LastConfigurationRow from './LastConfigurationRow'
import LastConfigurationsDateFilter from '../last-configurations-date-filter'
import { Table } from '../table'
import LastConfigurationsTablePlaceholder from './LastConfigurationsTablePlaceholder'

export type Props = {
    lastConfigurationsInfo: LastConfigurationInfo
    isLoading: boolean
    loadLastConfigurationsInfo: () => {}
    loadConfigurationByVehicleCode: (vehicleCode: string) => {}
}

const LastConfigurations: FunctionComponent<Props> = (props) => {
    const {
        lastConfigurationsInfo,
        isLoading,
        loadLastConfigurationsInfo,
        loadConfigurationByVehicleCode,
    } = props
    const {lastConfigurations} = lastConfigurationsInfo
    const {t} = useTranslation()

    const [selectedDate, setSelectedDate] = useState()

    useOnMount(() => {
        loadLastConfigurationsInfo && loadLastConfigurationsInfo()
    })

    const configurationItems = lastConfigurations.find(lastConfiguration => lastConfiguration.date === selectedDate)?.configurations
        .map(lastConfig => (
            <LastConfigurationRow
                key={lastConfig.vehicleCode}
                configurationInfo={lastConfig}
                loadConfigurationByVehicleCode={loadConfigurationByVehicleCode}
            />
        ))

    const configurationsTable = lastConfigurations.length === 0 && isLoading
        ? <LastConfigurationsTablePlaceholder/>
        : (<Table className={style.content}>
            <tbody>
            {configurationItems}
            </tbody>
        </Table>)

    return (
        <div className={style.container}>
            <section className={style.headerContainer}>
                <h1 className={style.title}>
                    {t('garage.lastConfigurations.heading')}
                </h1>
            </section>
            <LoadingIndicatorWrapper isLoading={isLoading}>
                <LastConfigurationsDateFilter
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                />
                {configurationsTable}
            </LoadingIndicatorWrapper>
        </div>
    )
}

export default LastConfigurations
