import React, { FunctionComponent } from 'react'
import { ConflictItem } from '../../entities/ConfigurationResponse'
import { Checkbox } from '../form'
import MediaImg from '../media-img'
import useTranslation from '../../hooks/useTranslation'
import style from './foresightedBuildabilityConflictStepItem.css'
import InfoButton from '../info-button'
import InfoI from '../info-i'
import {Markup} from "interweave"

export type Props = {
    item: ConflictItem
    checkboxIsChecked: boolean
    checkboxIsVisible: boolean
    checkboxIsDisabled: boolean
    openInfoModal: Function
    selectEquipment: Function
    deselectEquipment: Function
}

const ForesightedBuildabilityConflictStepItem: FunctionComponent<Props> = (props) => {
    const {
        item,
        checkboxIsChecked,
        checkboxIsVisible,
        checkboxIsDisabled,
        openInfoModal,
        deselectEquipment,
        selectEquipment,
    } = props
    const { t } = useTranslation()
    const {
        id,
        name,
        evaKey,
        price,
        media,
        content,
    } = item.equipment

    const openModal = (activeMedia: string|null = null) => () => openInfoModal({
        title: name,
        media,
        activeMedia,
        content,
    })

    const changeHandler = (): void => {
        if (checkboxIsChecked) {
            deselectEquipment(id)
        } else {
            selectEquipment(id)
        }
    }

    const checkboxElement = checkboxIsVisible ? (
        <span>
            <Checkbox
                checked={checkboxIsChecked}
                disabled={checkboxIsDisabled}
                onChange={changeHandler}
            />
        </span>
    ) : null
    const label = t(item.conflictType === 'ADD'
        ? 'foresightedBuildabilityModal.plus'
        : 'foresightedBuildabilityModal.minus')
    const className = [
        style.container,
        checkboxIsVisible ? style.containerWithCheckbox : '',
    ].join(' ')

    const hasInfo = media && media.length > 0
    const imgElement = hasInfo && <MediaImg alt={name} media={media} width={100} />
    const infoLabel = imgElement !== null ? imgElement : <InfoI isForced={false} />
    const infoElement = hasInfo ? (
        <InfoButton openModal={openModal(imgElement !== null ? 'image' : null)} dataCy={id}>
            {infoLabel}
        </InfoButton>
    ) : null

    return (
        <div className={className}>
            {checkboxElement}
            <span>
                <span className={style.label}>{label}</span>
                <span>
                    {' '}<Markup content={name}/>{' '}
                </span>
                <span className={style.evaKey}>{evaKey}</span>
            </span>
            <span>
                {infoElement}
            </span>
            <span className={style.price}>{price}</span>
        </div>
    )
}

export default ForesightedBuildabilityConflictStepItem
