import { ApiRequest } from '../../apiRequest'
import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import {
    fetchLastConfigurationsInfo,
    setLastConfigurationsInfoState,
    fetchLastConfigurationsInfoAsync,
} from '../../actions/app/lastConfigurations.actions'
import { getLastConfigurationsApiUrl } from '../../../constants/apiUrls'

const lastConfigurationsMiddleware =
    (apiRequest: ApiRequest): Middleware => (store) => (next) => (action) => {
        next(action)
        const {dispatch} = store

        if (isActionOf(fetchLastConfigurationsInfo, action)) {
            apiRequest(
                {
                    options: {
                        url: getLastConfigurationsApiUrl(),
                        method: 'GET',
                    },
                    asyncActions: fetchLastConfigurationsInfoAsync,
                    causedBy: action,
                },
                dispatch,
            )
        }

        if (isActionOf(fetchLastConfigurationsInfoAsync.success, action)) {
            const lastConfigurationsInfo = action.payload
            dispatch(setLastConfigurationsInfoState(lastConfigurationsInfo))
        }
    }

export default lastConfigurationsMiddleware
