import { Props } from './LastConfigurationsDateFilter'
import { connect, MapStateToProps } from 'react-redux'
import lastConfigurationsAvailabilitiesSelector
    from '../../redux/selectors/garage/lastConfigurationsAvailabilitiesSelector'
import countrySelector from '../../redux/selectors/i18n/countrySelector'
import languageSelector from '../../redux/selectors/i18n/languageSelector'
import lastConfigurationsIsPendingSelector
    from '../../redux/selectors/pending-request/lastConfigurationsIsPendingSelector'

type StateProps = Pick<Props, 'lastConfigurationsAvailabilities' | 'country' | 'language' | 'isLoading'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    lastConfigurationsAvailabilities: lastConfigurationsAvailabilitiesSelector(state),
    country: countrySelector(state),
    language: languageSelector(state),
    isLoading: lastConfigurationsIsPendingSelector(state),
})

export default connect(mapStateToProps)
