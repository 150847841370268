import { createAction, createAsyncAction } from 'typesafe-actions'
import LastConfigurationInfo from '../../../entities/LastConfigurationInfo'

export const setLastConfigurationsInfoState = createAction('DOC / LastConfigurationsInfo / set state')<LastConfigurationInfo>()

export const fetchLastConfigurationsInfo = createAction('CMD / LastConfigurationsInfo / fetch')()

export const fetchLastConfigurationsInfoAsync = createAsyncAction(
    'EVT / LastConfigurationsInfo / fetch request',
    'EVT / LastConfigurationsInfo / fetch success',
    'EVT / LastConfigurationsInfo / fetch failure',
)<undefined, LastConfigurationInfo, Error>()
