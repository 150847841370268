import LastConfigurationInfo from '../../entities/LastConfigurationInfo'
import { createReducer } from 'typesafe-actions'
import { setLastConfigurationsInfoState } from '../actions/app/lastConfigurations.actions'

export const defaultState: LastConfigurationInfo = {
    lastConfigurations: [],
    lastConfigurationsAvailabilities: [],
}

const lastConfigurationsInfoReducer = createReducer(defaultState)
    .handleAction(setLastConfigurationsInfoState, (state, action) => action.payload)

export default lastConfigurationsInfoReducer
