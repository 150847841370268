import { createSelector } from 'reselect'
import lastConfigurationsInfoSelector from './lastConfigurationsInfoSelector'
import LastConfigurationAvailability from '../../../entities/LastConfigurationAvailability'

const lastConfigurationsAvailabilitiesSelector = createSelector(
    lastConfigurationsInfoSelector,
    (lastConfigurationsInfo): LastConfigurationAvailability[] => {
        if (!lastConfigurationsInfo) {
            return []
        }
        const {lastConfigurationsAvailabilities} = lastConfigurationsInfo
        return lastConfigurationsAvailabilities.sort((a, b) => {
            const dateA = new Date(a.date).getTime()
            const dateB = new Date(b.date).getTime()
            return dateA - dateB
        })
    })
export default lastConfigurationsAvailabilitiesSelector
