import React, { ElementType, FunctionComponent, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import useTranslation from '../../hooks/useTranslation'
import NavLink, { NavLinkStatus } from '../../entities/NavLink'
import Logo from '../logo'
import ArrowLeftIcon from '../../assets/media/icons/arrow-left-small.svg'
import ListIcon from '../../assets/media/icons/list.svg'
import CompareModelIcon from '../../assets/media/icons/compare-models.svg'
import LastConfigurationsIcon from '../../assets/media/icons/last-configurations.svg'
import style from './navigation.css'
import {
    EQUIPMENT_GROUP_STATUS_ERRONEOUS,
    EQUIPMENT_GROUP_STATUS_NONE,
    EQUIPMENT_GROUP_STATUS_OK,
    EQUIPMENT_GROUP_STATUS_INCOMPLETE,
    EQUIPMENT_GROUP_STATUS_UNAVAILABLE,
} from '../../entities/EquipmentGroup'
import MiwiFeatureAppLoader from '../miwi-feature-app-loader'

export type Props = {
    navLinks: NavLink[]
    orderButtonsAreActive: boolean
    abortUrl: string | undefined
    isComparisonButtonVisible: boolean
    isGlcButtonVisible: boolean
}

const Navigation: FunctionComponent<Props> = (props) => {
    const { navLinks, orderButtonsAreActive, abortUrl, isComparisonButtonVisible, isGlcButtonVisible } = props
    const { t } = useTranslation()

    const createLabelElement = (label: string, isActive: boolean): ReactNode => {
        const labelClassName = isActive ? style.labelActive : style.label

        return (
            <div className={labelClassName}>
                {t(label)}
            </div>
        )
    }

    const createIconElement = (Icon: ElementType): ReactNode => {
        if (Icon == null) {
            return <ListIcon className={style.icon} />
        }

        return <Icon className={style.icon} />
    }

    const createTrafficLightElement = (status: NavLinkStatus): ReactNode => {
        const statusClassMap = {
            [EQUIPMENT_GROUP_STATUS_OK]: style.trafficLightDone,
            [EQUIPMENT_GROUP_STATUS_NONE]: style.trafficLightNone,
            [EQUIPMENT_GROUP_STATUS_INCOMPLETE]: style.trafficLightIncomplete,
            [EQUIPMENT_GROUP_STATUS_ERRONEOUS]: style.trafficLightError,
        }

        return <div className={`${style.trafficLight} ${statusClassMap[status]}`} />
    }

    const linkElements = navLinks.map((link) => {
        const {
            id,
            url,
            navLabel,
            title,
            iconComponent,
            isEnabled,
            isActive,
            status,
        } = link
        const listItemClassName = style[`listItem-${id}`]
        const formattedTitle = t(title)

        const content = (
            <>
                {createIconElement(iconComponent)}
                {createLabelElement(navLabel, isActive)}
                {createTrafficLightElement(status)}
            </>
        )
        const isDisable = EQUIPMENT_GROUP_STATUS_UNAVAILABLE === status ? style.noClick : ''

        const linkElement = isEnabled ? (
            <Link
                to={url}
                title={formattedTitle}
                className={`${style.link} ${isActive ? style.linkActive : ''} ${isDisable}`}
                data-cy={`nav-item-${id.replace(/\\/g, '.')}`}
            >
                {content}
            </Link>
        ) : <span className={style.linkDisabled}>{content}</span>

        return (
            <li key={id} className={`${style.listItem} ${listItemClassName}`}>
                {linkElement}
            </li>
        )
    })

    const abortLinkItem = orderButtonsAreActive && abortUrl ? (
        <li className={style.listItem}>
            <a href={abortUrl} className={style.link} data-cy="nav-item-abort">
                {createIconElement(ArrowLeftIcon)}
                {createLabelElement(t('abort.linkLabel'), true)}
                {createTrafficLightElement(undefined)}
            </a>
        </li>
    ) : null

    const comparisonButton = isComparisonButtonVisible ? (
        <li className={style.listItem}>
            <Link to="/compare" title="" className={`${style.link}`}>
                {createIconElement(CompareModelIcon)}
                {createLabelElement(t('compare.linkLabel'), true)}
            </Link>
        </li>
    ) : null

    const lastConfigurationsButton = isGlcButtonVisible ? (
        <li className={style.listItem}>
            <Link to="/last-configurations" title="" className={`${style.link}`}>
                {createIconElement(LastConfigurationsIcon)}
                {createLabelElement(t('garage.lastConfigurations.linkLabel'), true)}
            </Link>
        </li>
    ) : null

    const bottomLinkList = (
        <ul className={style.linkListLast}>
            {abortLinkItem}
            {comparisonButton}
            {lastConfigurationsButton}
            <li className={style.listItem}>
                <MiwiFeatureAppLoader />
            </li>
        </ul>
    )

    return (
        <nav className={style.container}>
            <Logo />
            <ul className={style.linkList}>
                {linkElements}
            </ul>
            {bottomLinkList}
        </nav>
    )
}

export default Navigation
