import React, { FunctionComponent, useContext } from 'react'
import style from './anchorNavigation.css'
import AnchorNavigationContext from './anchorNavigationContext'
import TextSelectDropdown from '../text-select-dropdown/TextSelectDropdown'
import { OptionType } from '../../entities/OptionType'

type AnchorNavigationOption = {
    title: string
    hashtag: string
}

export type Props = {
    options: AnchorNavigationOption[]
    label?: string
}

const AnchorNavigation: FunctionComponent<Props> = (props) => {
    const { options, label } = props

    const { getTopMostVisibleAnchorId } = useContext(AnchorNavigationContext)

    const setHashtag = (selectedOption: OptionType): void => {
        window.location.hash = selectedOption?.value || ''
    }

    const optionElements = options.map((option) => ({
        value: option.hashtag,
        label: option.title,
    }))

    const topMostVisibleAnchorId = getTopMostVisibleAnchorId()

    return (
        <div className={style.container}>
            <span className={style.label}>{label}</span>
            <TextSelectDropdown
                options={optionElements}
                onChange={setHashtag}
                value={optionElements.find((opt) => opt.value === topMostVisibleAnchorId) || null}
                placeholder={optionElements[0].label}
                isSearchable={false}
            />
        </div>
    )
}

export default AnchorNavigation
