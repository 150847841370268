import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import style from './textFilter.css'
import TextSelectDropdown from '../text-select-dropdown/TextSelectDropdown'
import { OptionType } from '../../entities/OptionType'
import { Markup } from 'interweave'

export type Props = {
    suggestions: string[]
    setFilter: (value: string) => void
    value: string
}

const TextFilter: FunctionComponent<Props> = (props) => {
    const {
        suggestions,
        setFilter,
        value,
    } = props
    const { t } = useTranslation()

    const options = suggestions.map((word) => ({
        value: word,
        label: <Markup content={word}/>,
    }))

    const onInputChange = (inputValue: string) => {
        if (suggestions.some(suggestion => suggestion.toLowerCase() === inputValue.toLowerCase())) {
            setFilter(inputValue)
        }
    }

    const filterChange = (selectedOption: OptionType) => {
        setFilter(selectedOption?.value || '')
    }

    const foundOption = options.find((option) => option.value === value) || null
    return (
        <div className={style.container}>
            <label className={style.label}>{t('textFilter.label')}</label>
            <TextSelectDropdown
                options={options}
                onChange={filterChange}
                onInputChange={onInputChange}
                value={foundOption}
                placeholder={foundOption?.value || ''}
            />
        </div>
    )
}

export default TextFilter
