import React, { FunctionComponent } from 'react'
import style from './lastConfigurations.css'
import GarageConfigurationInfo from '../../entities/GarageConfigurationInfo'
import useTranslation from '../../hooks/useTranslation'
import { Td, Tr } from '../table'
import ExteriorImagePlaceholder from '../../assets/media/car-silhouette41.svg'
import InteriorImagePlaceholder from '../../assets/media/car-interior.svg'

export type Props = {
    configurationInfo: GarageConfigurationInfo
    loadConfigurationByVehicleCode: (vehicleCode: string) => {}
}

const appendQueryParameter = (url: string, key: string, value: string) => {
    if (!url) {
        return null
    }
    try {
        const urlObj = new URL(url)
        urlObj.searchParams.set(key, value)
        return urlObj.toString()
    } catch (error) {
        return null
    }
}

const LastConfigurationRow: FunctionComponent<Props> = (props) => {
    const {configurationInfo, loadConfigurationByVehicleCode} = props
    const {t} = useTranslation()

    const exteriorImage = appendQueryParameter(configurationInfo.exteriorImageUrl, 'wid', '400')
    const exteriorImageElement = exteriorImage ?
        <img
            src={exteriorImage}
            alt='exterior'/>
        : <ExteriorImagePlaceholder className={style.placeholder}/>

    const interiorImage = appendQueryParameter(configurationInfo.interiorImageUrl, 'wid', '400')
    const interiorImageElement = interiorImage ?
        <img
            src={interiorImage}
            alt='exterior'/>
        : <InteriorImagePlaceholder className={style.placeholder}/>

    return (
        <Tr>
            <Td className={style.configDetails}>
                <div className={style.details}>
                    <div className={style.vehicleData}>
                        <strong>{configurationInfo.modelName}</strong>
                        <div>{configurationInfo.totalPrice}</div>
                        <div>
                            <strong>{t('garage.lastConfigurations.vehicleCode')}</strong> {configurationInfo.vehicleCode}
                        </div>
                    </div>
                    <button
                        onClick={() => loadConfigurationByVehicleCode(configurationInfo.vehicleCode)}
                        className={style.loadVehicleCodeButton}>
                        {t('garage.lastConfigurations.showVehicleCode')}
                    </button>
                </div>
                <div className={style.media}>
                    {exteriorImageElement}
                    {interiorImageElement}
                </div>
            </Td>
        </Tr>)
}

export default LastConfigurationRow
