import React, { FunctionComponent, useEffect } from 'react'
import LastConfigurationAvailability from '../../entities/LastConfigurationAvailability'
import useTranslation from '../../hooks/useTranslation'
import TextSelectDropdown from '../text-select-dropdown/TextSelectDropdown'
import { OptionType } from '../../entities/OptionType'
import style from './lastConfigurationDateFilter.css'

const formatAvailabilityDate = (date: string, language: string, country: string): string => {
    if (!date) {
        return null
    }
    const day = new Date(date).toLocaleDateString(language + '-' + country, { weekday: 'long' }) //de-DE
    const dateTokens = date.split('-')
    return `${dateTokens[2]}.${dateTokens[1]} ${day.slice(0, 2)}`
}

export type Props = {
    lastConfigurationsAvailabilities: LastConfigurationAvailability[]
    selectedDate: string
    setSelectedDate: Function //React function
    country: string
    language: string
    isLoading: boolean
}

const LastConfigurationsDateFilter: FunctionComponent<Props> = (props) => {
    const {
        lastConfigurationsAvailabilities,
        selectedDate,
        setSelectedDate,
        country,
        language,
        isLoading,
    } = props
    const {t} = useTranslation()

    useEffect(() => {
        if (lastConfigurationsAvailabilities.length > 0) {
            setSelectedDate(lastConfigurationsAvailabilities.at(-1)?.date || "")
        }
    }, [lastConfigurationsAvailabilities, setSelectedDate])

    const handleChange = (selectedOption: OptionType) => {
        if (selectedOption) {
            setSelectedDate(selectedOption.value)
        }
    }

    const customStyles = {
        valueContainer: (providedStyles) => ({
            ...providedStyles,
            display: "flex",
            justifyContent: "center",
        }),
        option: (provided, state) => ({
            ...provided,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: state.isFocused
                ? "#e6e6e6"
                : "transparent",
            color: state.isSelected ? "#000" : "#333",
            ':hover': {
                backgroundColor: "#e6e6e6",
            },
        }),
    }

    const options = lastConfigurationsAvailabilities.map((item) => {
        const {date, numberOfAvailableConfigurations} = item
        const availableVehiclesName = numberOfAvailableConfigurations > 1
            ? t('garage.lastConfigurations.dateFilter.vehicles')
            : t('garage.lastConfigurations.dateFilter.vehicle')

        return {
            value: item.date,
            label: (
                <>
                <span className={style.date}>
                    {formatAvailabilityDate(date, language, country)}
                </span>
                <span className={style.vehicles}>
                    {numberOfAvailableConfigurations} {availableVehiclesName}
                </span>
                </>
            ),
        }
    })

    const CustomSingleValue = ({ data }) => (
        <span>{formatAvailabilityDate(data.value, language, country)}</span>
    )

    return (
        <div className={style.dropdownContainer}>
            <div className={style.dropdownSelect}>
                <TextSelectDropdown
                    options={options}
                    onChange={handleChange}
                    value={options.find((option) => option.value === selectedDate) || null}
                    placeholder={isLoading ? t('loading') : formatAvailabilityDate(options.at(-1)?.value, language, country)}
                    singleValueCustomComponent={CustomSingleValue}
                    isClearable={false}
                    isSearchable={false}
                    isLoading={isLoading && (!lastConfigurationsAvailabilities || lastConfigurationsAvailabilities.length === 0)}
                    styles={customStyles}
                />
            </div>
        </div>
    )
}

export default LastConfigurationsDateFilter
