import React, { FunctionComponent } from 'react'
import Select from 'react-select'
import { CSSObject } from 'styled-components'
import { OptionType } from '../../entities/OptionType'
import style from './textSelectDropdown.css'
import { ComponentType } from 'react'

export type Props = {
    options: OptionType[]
    onChange: (option: OptionType) => void
    onInputChange?: (inputValue: string) => void
    value: OptionType
    isSearchable?: boolean
    placeholder: string
    isClearable?: boolean
    singleValueCustomComponent?: ComponentType<{ data: OptionType }>
    isLoading?: boolean
    styles?: any
}

const TextSelectDropdown: FunctionComponent<Props> = (props) => {
    const {
        options,
        onChange,
        value,
        isSearchable = true,
        placeholder,
        isClearable = true,
        singleValueCustomComponent,
        isLoading = false,
        styles = {},
        onInputChange,
    } = props

    const customStyles = {
        option: (styles: CSSObject, {isFocused, isSelected}) => ({
            ...styles,
            backgroundColor: isFocused
                ? 'var(--color-grey-15)'
                : 'transparent',
            color: isSelected ? 'var(--color-black)' : 'var(--color-grey-80)',
            ':hover': {
                backgroundColor: 'var(--color-grey-15)',
                color: 'var(--color-black)',
            },
        }),
    }

    const mergedStyles = {
        ...styles,
        ...customStyles,
        option: (base: CSSObject, state: any) => ({
            ...customStyles.option(base, state),
            ...(styles?.option ? styles.option(base, state) : {}),
        }),
    }

    return (
            <Select
                options={options}
                onChange={onChange}
                onInputChange={onInputChange}
                value={value}
                placeholder={placeholder}
                isClearable={isClearable}
                isSearchable={isSearchable}
                className={style.dropdown}
                classNamePrefix={style.dropdown}
                components={{
                    ...(singleValueCustomComponent ? { SingleValue: singleValueCustomComponent } : {}),
                    IndicatorSeparator: () => null,
                }}
                styles={mergedStyles}
                isLoading={isLoading}
            />
    )
}

export default TextSelectDropdown
