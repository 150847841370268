import { connect, MapStateToProps } from 'react-redux'
import { Props } from './LastConfigurations'
import { fetchLastConfigurationsInfo } from '../../redux/actions/app/lastConfigurations.actions'
import lastConfigurationsInfoSelector
    from '../../redux/selectors/garage/lastConfigurationsInfoSelector'
import lastConfigurationsIsPendingSelector
    from '../../redux/selectors/pending-request/lastConfigurationsIsPendingSelector'
import { loadConfigurationByVehicleCode } from '../../redux/actions/app/vehicleCode.actions'

type StateProps = Pick<Props, 'lastConfigurationsInfo' | 'isLoading'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    lastConfigurationsInfo: lastConfigurationsInfoSelector(state),
    isLoading: lastConfigurationsIsPendingSelector(state),
})

type DispatchProps = Pick<Props, 'loadLastConfigurationsInfo'  | 'loadConfigurationByVehicleCode'>

const mapDispatchToProps: DispatchProps = {
    loadLastConfigurationsInfo: fetchLastConfigurationsInfo,
    loadConfigurationByVehicleCode: loadConfigurationByVehicleCode,
}

export default connect(mapStateToProps, mapDispatchToProps)
