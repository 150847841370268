import React, { FunctionComponent } from 'react'
import style from './lastConfigurations.css'
import useTranslation from '../../hooks/useTranslation'
import { Table, Td, Tr } from '../table'
import ExteriorImagePlaceholder from '../../assets/media/car-silhouette41.svg'
import InteriorImagePlaceholder from '../../assets/media/car-interior.svg'

const LastConfigurationsTablePlaceholder: FunctionComponent = () => {
    const {t} = useTranslation()

    return (<Table className={style.content}>
        <tbody>
        {[...Array(5)].map((element, index) => (
            <Tr key={index}>
                <Td className={`${style.configDetails} ${style.configDetailsPlaceholder}`}>
                    <div className={style.details}>
                        <div className={style.detailsTopPlaceholder}>
                            <strong>{t('loading')}</strong>
                        </div>
                    </div>
                    <div className={style.mediaPlaceholder}>
                        <ExteriorImagePlaceholder/>
                        <InteriorImagePlaceholder/>
                    </div>
                </Td>
            </Tr>
        ))}

        </tbody>
    </Table>)
}

export default LastConfigurationsTablePlaceholder
